export const ApplicatinStatus: string[] = [
    "APPLIED",
    "ACCEPTED",
    "REVIEW",
    "DECLINED"
];

export const CertLevels: string[] = [
    "MSC", 
    "PHD", 
    "HIGHSCHOOL", 
    "BSC", 
    "DEGREE", 
    "DOCTORATE"
];
